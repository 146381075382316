require('intersection-observer');

const options = {
    root: null, // 今回はビューポートをルート要素とする
    rootMargin: "0px 0px -90% 0px", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
};

const observer = new IntersectionObserver(doWhenIntersect, options);

const boxes = document.querySelectorAll("#contents");
//IE11対策
const boxesArr = Array.prototype.slice.call(boxes);
boxesArr.forEach(box => {
    observer.observe(box);
});

function doWhenIntersect(entries) {
    //IE11対策
    const entriesArr = Array.prototype.slice.call(entries);
    entriesArr.forEach(entry => {
        if (entry.isIntersecting) {
            document.getElementById('banner').classList.add("inview");
        }else{
            document.getElementById('banner').classList.remove("inview");
        }
    });
}


//バナーのcloseを押した処置
const banner = document.querySelector("#banner");
const close = document.querySelector("#close");
close.addEventListener("click", event => {
    banner.classList.add("hidden")
})
